import React, { useState, useEffect } from 'react'


const Loader = () => {
    const [offset, setOffset] = useState(0);
    let x = 0;
    const [displayX, setDisplayX] = useState(x);
    const r = 15;
    const c = r * 2 * Math.PI;

    const loadCircle = () => {
        setOffset(-c * (Math.sin((x / 10) - Math.PI / 2)) + c);
        x += 1;
        setDisplayX(x);
    }

    useEffect(() => {
        setInterval(loadCircle, 50);
        // eslint-disable-next-line
    }, []);
    return (
        <div id='loader'>
          <h1>Loading</h1>
          <svg height='100' width='100'>
            <circle
            cx='50'
            cy='50'
            r={r}
            fill='transparent'
            stroke={`hsl(${displayX}, 70%, 50%)`}
            strokeWidth='10'
            strokeDasharray={c}
            strokeDashoffset={offset}
            transform='rotate(-90 50 50)'
            />
          </svg>
        </div>
      )
}

export default Loader
