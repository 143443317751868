import React, { useState } from 'react';
import { createPost } from '../store/actions/postActions.js';
import { connect } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MdNoteAdd } from 'react-icons/md';
import { BsFilePost } from 'react-icons/bs';
import '../stylesheets/New.css';
import firebase from '../configs/FirebaseConfig.js';
import { useHistory } from "react-router-dom";
import Loader from '../views/partials/Loader.jsx';

const New = ({ createPost }) => {
    const history = useHistory()
    const [posting, setPosting] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const storage = firebase.storage();
    const [form, setForm] = useState({
        title: '',
        image: '',
        content: ''
    });
    const [progress, setProgress] = useState(0);

    const handleInput = (field, e, data) => {
        switch (field) {
            case 'title':
                setForm({ ...form, title: e.target.value });
                break;
            case 'content':
                setForm({ ...form, content: data });
                break;
            default:
                return null;
        }
    }

    const handleUpload = e => {
        if(isUploaded) {
            storage.ref(form.imageRef).delete().then(() => {
                setIsUploaded(false);
            }).catch(err => {
                console.log('ERROR:', err);
            });
        }
        const image = e.target.files[0];
        const uploadTask = storage.ref(`post-images/${image.name}`).put(image);
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref('post-images')
                    .child(image.name.toString())
                    .getDownloadURL()
                    .then(url => {
                        setForm({ ...form, image: url, imageRef: `post-images/${image.name}` });
                    });
                setIsUploaded(true);
            }
        )
    }

    const handlePost = () => {
        setPosting(true)
        createPost(form);
        setTimeout(() => {
            setPosting(false)
            history.push('/index');
        }, 2000);
    }

    return (
        <>
            {posting ? <Loader /> :
                <main className='new'>
                    <h1>New Post</h1>
                    <div id='new-post-form'>
                        <fieldset className='new-post-field'>
                            <label htmlFor='title'>Title <MdNoteAdd /></label>
                            <input onChange={e => handleInput('title', e)} type='text' name='title' id='new-post-title' placeholder='Title'/>
                        </fieldset>
                        <fieldset className='new-post-field'>
                            <label htmlFor='image'>Image Upload</label>
                            <input type='file' name='image' id='new-post-image' onChange={e => handleUpload(e)} />
                            <progress 
                            value={progress} 
                            max='100' 
                            style={ progress === 100 || progress === 0  ? { display: 'none' } : null } 
                            />
                            <img style={ form.image === '' ? { display: 'none' } : null } src={form.image} alt='' id='upload-image' />
                        </fieldset>
                        <fieldset className='new-post-field' id='new-post-content'>
                            <label htmlFor='content'>Post Content<BsFilePost /></label>
                            <CKEditor 
                            editor={ ClassicEditor } 
                            onChange={ (e, editor) => {
                                const data = editor.getData();
                                handleInput('content', e, data)
                            }}
                            />
                        </fieldset>
                        <fieldset className='new-post-field'>
                            <button className='new-form-button' onClick={handlePost}>Submit</button>
                        </fieldset>
                    </div>
                </main>
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    createPost: post => dispatch(createPost(post))
});

export default connect(null, mapDispatchToProps)(New)