import firebase from 'firebase/app';
import store from '../store/store.js';
import { createFirestoreInstance } from 'redux-firestore';

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}

export default rrfProps