export const createPost = post => (
    (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore =  getFirestore();
        const slug = post.title.toString().toLowerCase()
            .replace(/\s+/g, '-')        // Replace spaces with -
            .replace(/[^\w-]+/g, '')     // Remove all non-word chars
            .replace(/--+/g, '-')        // Replace multiple - with single -
            .replace(/^-+/, '')          // Trim - from start of text
            .replace(/-+$/, '')          // Trim - from end of text
            .substring(0, 75);           // Trim at 75 characterss
        firestore.collection('posts').doc(slug).set({
            ...post,
            content: {
                __html: post.content
            },
            slug,
            timeCreated: firestore.FieldValue.serverTimestamp()
        }).then(() => {
            dispatch({ type: 'CREATE_POST', post });
        }).catch(err => {
            dispatch({ type: 'CREATE_POST_ERROR', err })
        });
    }
);

export const editPost = post => (
    (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore =  getFirestore();
        console.log(post)
        firestore.collection('posts').doc(post.slug).set({
            ...post,
            timeEdited: firestore.FieldValue.serverTimestamp()
        }).then(() => {
            dispatch({ type: 'EDIT_POST', post });
        }).catch(err => {
            dispatch({ type: 'EDIT_POST_ERROR', err })
        });
    }
);