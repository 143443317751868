const initState = {}

const postReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_POST':
            console.log('Created post', action.post);
            return state;
        case 'CREATE_POST_ERROR':
            console.log('Create post error', action.err);
            return state;
        case 'EDIT_POST':
            console.log('Edited post', action.post);
            return state;
        case 'EDIT_POST_ERROR':
            console.log('Edit post error', action.err);
            return state;
        default:
            return state;
    }
}

export default postReducer