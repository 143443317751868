import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaUsersCog } from 'react-icons/fa';
import { BsNewspaper} from 'react-icons/bs';
import '../stylesheets/Dashboard.css';

const Dashboard = () => {
    return (
        <main className='dashboard'>
            <h1>Dashboard</h1>
            <section className='dashboard-links'>
                <Link to='/new'><span className='dashboard-link-icons'><FaPlus size={70} /></span> New Post</Link>
                <Link to='/index'><span className='dashboard-link-icons'><BsNewspaper size={70} /></span> View Posts</Link>
                <Link to='/users'><span className='dashboard-link-icons'><FaUsersCog size={70} /></span> Users</Link>
            </section>
        </main>
    )
}

export default Dashboard