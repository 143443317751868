import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import firebase from '../configs/FirebaseConfig.js';
import { personalizeUser } from '../store/actions/authActions';
import '../stylesheets/CreateNewUser.css';

const CreateNewUser = ({ personalizeUser, authError }) => {
    const location = useLocation();

    function getQueryVariable(variable) {
        const query = location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    }

    const [form, setForm] = useState({
        username: '',
        password: '',
        image: '',
        imageRef: '',
        email: getQueryVariable('email'),
        firstName: getQueryVariable('first'),
        lastName: getQueryVariable('last'),
        userPermit: getQueryVariable('permit')
    });

    const [isUploaded, setIsUploaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const storage = firebase.storage();
    const history = useHistory();

    const handleUpload = e => {
        if(isUploaded) {
            storage.ref(form.imageRef).delete().then(() => {
                setIsUploaded(false);
            }).catch(err => {
                console.log('ERROR:', err);
            });
        }
        const image = e.target.files[0];
        const uploadTask = storage.ref(`user-images/${image.name}`).put(image);
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref('user-images')
                    .child(image.name.toString())
                    .getDownloadURL()
                    .then(url => {
                        setForm({ ...form, image: url, imageRef: `user-images/${image.name}` });
                    });
                    setIsUploaded(true);
            }
        )
    }

    const checkCompleted = () => {
        for(let input in form) {
            if(form[input] === '') {
                return false;
            }
        }
        return true;
    }

    const handleSubmit = () => {
        const isCompleted = checkCompleted();
        if(!isCompleted) {
            setError('Please fill out all fields');
        } else if(authError) {
            return;
        } else {
            history.push('/dashboard');
            personalizeUser(form);
        }
    }

    return (
        <main className='create-new-user'>
            <h1>Hi, {`${form.firstName} ${form.lastName}`}</h1>
            <h3>Let's create your new account for The Hawaii Trader</h3>
            <div className='create-user-form'>
                <div className='form-group'>
                    <label htmlFor='username'>Username</label>
                    <input type='text' onChange={e => setForm({ ...form, username: e.target.value })} />
                </div>
                <div className='form-group'>
                    <label htmlFor='password'>Password</label>
                    <input type='password' onChange={e => setForm({ ...form, password: e.target.value })} />
                </div>
                <div className='form-group'>
                    <label htmlFor='image'>Profile Image</label>
                    <input type='file' onChange={e => handleUpload(e)} />
                    <progress 
                    value={progress} 
                    max='100' 
                    style={ progress === 100 || progress === 0  ? { display: 'none' } : null } 
                    />
                    <img style={ form.image === '' ? { display: 'none' } : null } src={form.image} alt='' id='new-user-image' />
                </div>
                <div className="form-group">
                    <input type='submit' onClick={handleSubmit} />
                    { error || authError ? <p>{error || authError}</p> : null }
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => {
    return {
        authError: state.auth.authError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        personalizeUser: newInfo => dispatch(personalizeUser(newInfo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUser)