import React from 'react';
import { useSelector } from 'react-redux';
import PostInfo from '../components/PostInfo.jsx';
import { useFirestoreConnect } from 'react-redux-firebase';
import '../stylesheets/Show.css';

const Show = () => {
    useFirestoreConnect([
        { collection: 'posts' }
    ]);
    const posts = useSelector(state => state.firestore.ordered.posts);

    return (
        <main className='show'>
            <h1 className='show-title'>Your Posts</h1>
            <section className='show-posts'>
                <ul className="post-info-head">
                    <li>Main Image</li>
                    <li>Date Created</li>
                    <li>Title</li>
                </ul>
                {posts && posts.map(post => (
                    <PostInfo key={post.slug} post={post} />
                ))}
            </section>
        </main>
    )
}

export default Show