import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import store from './store/store.js';
import rrfProps from './configs/ReduxFirebase.js';
import AuthIsLoaded from './components/AuthIsLoaded.jsx';
import './stylesheets/index.css';
import './stylesheets/components/Loader.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps} >
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);