import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { editPost } from '../store/actions/postActions.js';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MdNoteAdd, MdImage } from 'react-icons/md';
import { BsFilePost } from 'react-icons/bs';
import Loader from './partials/Loader';
import firebase from '../configs/FirebaseConfig.js';
import '../stylesheets/Edit.css';

const Edit = ({ editPost }) => {
    const history = useHistory();
    const { slug } = useParams();
    const storage = firebase.storage()

    useFirestoreConnect(`posts/${slug}`);

    const post = useSelector(({ firestore: { data } }) => data.posts && data.posts[slug]);

    const [form, setForm] = useState(post);
    const [progress, setProgress] = useState(0);

    const handleUpload = e => {
        storage.ref(form.imageRef).delete().catch(err => {
            console.log('ERROR:', err);
        });

        const image = e.target.files[0];
        const uploadTask = storage.ref(`post-images/${image.name}`).put(image);
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref('post-images')
                    .child(image.name.toString())
                    .getDownloadURL()
                    .then(url => {
                        setForm({ ...form, image: url, imageRef: `post-images/${image.name}` });
                    });
            }
        )
    }

    const handleSubmit = () => {
        editPost(form);
        history.push('/index');
    }

    return (
        <>
            {!post ? <Loader /> :
            <main className='edit'>
                <h1>Edit Post</h1>
                <div id='edit-post-form'>
                    <fieldset className='edit-post-field'>
                        <label htmlFor='title'>Title <MdNoteAdd /></label>
                        <input
                        type='text'
                        name='title'
                        id='edit-post-title'
                        defaultValue={post.title}
                        onChange={e => setForm({ ...form, title: e.target.value })}
                        />
                    </fieldset>
                    <fieldset className='edit-post-field'>
                        <label htmlFor='edit-post-image' className='edit-form-button'>Image <MdImage /></label>
                        <input type='file' name='image' id='edit-post-image' onChange={e => handleUpload(e)} />
                        <progress 
                            value={progress} 
                            max='100' 
                            style={ progress === 100 || progress === 0  ? { display: 'none' } : null } 
                            />
                        <img id='edit-upload-image' src={form.image} alt='' />
                    </fieldset>
                    <fieldset className='edit-post-field' id='edit-post-content'>
                        <label htmlFor='content'>Post Content<BsFilePost /></label>
                        <CKEditor
                        editor={ ClassicEditor }
                        data={post.content.__html}
                        onChange={(e, editor) => setForm({ ...form, content: { __html: editor.getData()} })}
                        />
                    </fieldset>
                    <fieldset className='new-post-field'>
                        <input
                        className='edit-form-button'
                        type='submit'
                        onClick={handleSubmit}
                        />
                    </fieldset>
                </div>
            </main>
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    editPost: post => dispatch(editPost(post))
});

export default connect(null, mapDispatchToProps)(Edit)