import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdDashboard } from 'react-icons/md';
import { FaPlus, FaUsersCog } from 'react-icons/fa';
import { BsNewspaper} from 'react-icons/bs';
import '../../stylesheets/partials/Sidebar.css';

const Sidebar = () => {
    const location = useLocation();
    const show = location.pathname.includes('/login') || location.pathname.includes('/users/new/');
    return (
        <aside className='sidebar' id={!show ? '' : 'hiddenSidebar'}>
            <nav className='sidebar-list'>
                    <Link to='/dashboard'>
                        <li className='sidebar-list-item'>
                            <MdDashboard />
                            <span className='sidebar-text'>
                                Dashboard
                            </span>
                        </li>
                    </Link>
                    <Link to='/new'>
                        <li className='sidebar-list-item'>
                            <FaPlus /> 
                            <span className='sidebar-text'>
                                New Post
                            </span>
                        </li>
                    </Link>
                    <Link to='/index'>
                        <li className='sidebar-list-item'>
                            <BsNewspaper /> 
                            <span className='sidebar-text'>
                                View Posts
                            </span>
                        </li>
                    </Link>
                    <Link to='/users'>
                        <li className='sidebar-list-item'>
                            <FaUsersCog /> 
                            <span className='sidebar-text'>
                                User Settings
                            </span>
                        </li>
                    </Link>
            </nav>
        </aside>
    )
}

export default Sidebar