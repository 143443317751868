import React, { useState } from 'react';
import { connect } from 'react-redux';
import { inviteUser } from '../store/actions/authActions.js';
import '../stylesheets/NewUser.css';

const NewUser = ({ auth, authError, inviteUser }) => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userPermit: 'writer',
    });

    const handleSubmit = () => {
        inviteUser(form);
    }

    return (
        <main className='new-user'>
            <h1>New User</h1>
            <div className='new-user-form'>
                <fieldset className='new-user-field'>
                    <input
                    type='text'
                    placeholder='John'
                    onChange={e => setForm({ ...form, firstName: e.target.value })} />
                    <label htmlFor='new-user-first'>First Name</label>
                </fieldset>
                <fieldset className='new-user-field'>
                    <input
                    type='text'
                    placeholder='Doe'
                    onChange={e => setForm({ ...form, lastName: e.target.value })}
                    />
                    <label htmlFor='new-user-last'>Last Name</label>
                </fieldset>
                <fieldset className='new-user-field'>
                    <label htmlFor='new-user-permissions'>Permissions</label>
                    <select
                    name='new-user-permissions'
                    id='new-user-permissions'
                    className='new-user-permissions'
                    onChange={e => setForm({ ...form, userPermit: e.target.selectedOptions[0].value })}
                    >
                        <option value='writer'>Writer</option>
                        <option value='admin'>Admin</option>
                    </select>
                </fieldset>
                <fieldset className='new-user-field'>
                    <input
                    type='text'
                    placeholder='email@email.com'
                    onChange={e => setForm({ ...form, email: e.target.value })}
                    />
                    <label htmlFor='new-user-email'>Email</label>
                </fieldset>
                <fieldset className='new-user-field'>
                    <input className='new-user-submit' type='submit' onClick={handleSubmit} />
                </fieldset>
                <div>
                    { authError ? <p>{ authError }</p> : null }
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inviteUser: newUser => dispatch(inviteUser(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUser)