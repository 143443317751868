import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute'
import Navbar from './views/partials/Navbar';
import Sidebar from './views/partials/Sidebar.jsx';
import Dashboard from './views/Dashboard.jsx';
import Login from './views/Login.jsx'
import New from './views/New.jsx';
import Show from './views/Show.jsx';
import Edit from './views/Edit.jsx';
import Users from './views/Users.jsx';
import NewUser from './views/NewUser.jsx';
import CreateNewUser from './views/CreateNewUser.jsx';

function App() {
  return (
    <div className="App">
        <Navbar />
        <Sidebar />
        <Switch>
          <Route path='/' exact>
            <Redirect to='/dashboard' />
          </Route>
          <Route path='/login' exact>
              <Login />
          </Route>
          <PrivateRoute path='/dashboard' exact>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path='/new' exact>
            <New />
          </PrivateRoute>
          <PrivateRoute path='/index' exact>
            <Show />
          </PrivateRoute>
          <PrivateRoute path='/index/edit/:slug' exact>
            <Edit />
          </PrivateRoute>
          <PrivateRoute path='/users' exact>
            <Users />
          </PrivateRoute>
          <PrivateRoute path='/users/new' exact>
            <NewUser />
          </PrivateRoute>
          <PrivateRoute path='/users/new/create' exact>
            <CreateNewUser />
          </PrivateRoute>
        </Switch>
    </div>
  );
}

export default App;
