const initState = {
    authError: null
}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'LOGIN_ERROR':
            console.log('login failed');
            return {
                ...state,
                authError: action.err.message
            }
        case 'LOGIN_SUCCESS':
            console.log('login success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Signout success');
            return state;
        case 'INVITE_SUCCESS':
            console.log('Invite success');
            return {
                ...state,
                authError: null
            }
        case 'INVITE_ERROR':
            console.log('Invite error');
            return {
                ...state,
                authError: action.err.message
            }
        case 'PERSONALIZE_SUCCESS':
            console.log('Personalize success');
            return {
                ...state,
                authError: null
            }
        case 'PERSONALIZE_ERROR':
            console.log('Personalize error', action.err);
            return {
                ...state,
                authError: action.err.message
            }
        default: 
            return state;
    }
}

export default authReducer