import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../configs/FirebaseConfig.js';
import { FiEdit, FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi';
import ShowCard from './ShowCard.jsx';
import '../stylesheets/components/PostInfo.css';

const PostInfo = ({ post }) => {
    const { slug, image, timeCreated, title, imageRef } = post;
    const [isShown, setIsShown] = useState(false);

    const toggleIsShown = () => {
        setIsShown(!isShown);
    }

    const handleDelete = () => {
        firebase.firestore().collection('posts').doc(slug).delete().then(() => {
            firebase.storage().ref(imageRef).delete();
        }).catch(err => {
            console.log('ERROR:', err);
        });
    }

    return (
        <ul className='post-info'>
            <li>
                <img className='inline-img' src={image} alt='article'/>
            </li>
            <li>
                {timeCreated.toDate().toDateString().substring(4)}
            </li>
            <li>
                {`${title.substring(0, 25)}...`}
            </li>
            <li 
            id='edit-button' 
            className='show-buttons'>
                <Link to={`/index/edit/${slug}`} >
                    <FiEdit />
                    Edit
                </Link>
            </li>
            <li 
            id='delete-button' 
            className='show-buttons' 
            onClick={handleDelete}>
                <FiTrash2 />
                Delete
            </li>
            <li 
            id='show-more-button' 
            onClick={toggleIsShown}>
                {isShown ? <FiChevronUp /> : <FiChevronDown />}
            </li>
            <li 
            id='show-card'>
                <ShowCard post={post} isShown={isShown} />
            </li>
        </ul>
    )
}

export default PostInfo