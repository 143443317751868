import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Hamburger from '../../components/Hamburger.jsx';
import Dropdown from '../../components/Dropdown.jsx';
import '../../stylesheets/partials/Navbar.css';


const Navbar = () => {
    const location = useLocation();
    const show = location.pathname.includes('/login') || location.pathname.includes('/users/new/');
    return (
        <nav className='navbar' id={!show ? '' : 'hiddenNav'}>
            <div id='brand'>
              <Link to='/' >The Hawaii Trader</Link>
            </div>
            <Hamburger>
                <Dropdown />
            </Hamburger>
        </nav>
    )
}

export default Navbar