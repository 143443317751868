import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import Loader from '../views/partials/Loader';

const AuthIsLoaded = ({ children }) => {
    const auth = useSelector(state => state.firebase.auth);

    if (!isLoaded(auth)) return <Loader />

    return children;
}

export default AuthIsLoaded