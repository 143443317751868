export const signIn = credentials => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err })
        })
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' });
        })
    }
}

export const inviteUser = newUser => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const { email, firstName, lastName, userPermit } = newUser

        const actionCodeSettings = {
            url: `http://${window.location.host}/users/new/create/?email=${email}&first=${firstName}&last=${lastName}&permit=${userPermit}`,
            handleCodeInApp: true
        }

        firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings) 
            .then(() => {
                dispatch({ type: 'INVITE_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'INVITE_ERROR', err });
            })
    }
}

export const personalizeUser = newInfo => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(newInfo.email, newInfo.password)
            .then(res => {
                const { user } = res;
                
                firestore
                    .collection('users')
                    .doc(user.uid).set({
                        firstName: newInfo.firstName,
                        lastName: newInfo.lastName,
                        userPermit: newInfo.userPermit,
                        imageRef: newInfo.imageRef
                    });
                return user
            }).then(user => {
                user.updateProfile({
                    displayName: newInfo.username,
                    photoURL: newInfo.image
                });
                return user
            }).then(user => {
                user.updatePassword(newInfo.password);
                return user
            }).then(() => {
                dispatch({ type: 'PERSONALIZE_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'PERSONALIZE_ERROR', err });
            });
    }
}