import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group'
import { signOut } from '../store/actions/authActions';
import { FaUserAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { MdSettings } from 'react-icons/md';
import { connect } from 'react-redux';

const Dropdown = (props) => {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);

    const calcHeight = el => {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    const DropdownItem = (props) => {
        return (
            <span onClick={props.onClick ? props.onClick : null} className='menu-item'>
                <span>{props.icon}</span>
                {props.children}
                <span className='right-icon'>{props.rightIcon}</span>
            </span>
        )
    }
    return (
        <div className='dropdown' style={{ height: menuHeight }}>
                <CSSTransition 
                in={activeMenu === 'main'} 
                unmountOnExit
                timeout={500}
                classNames='menu-primary'
                onEnter={calcHeight}
                >

                    <div className='menu'>
                        <DropdownItem
                        icon={<FaUserAlt/>}
                        rightIcon={<FaChevronRight/>}
                        onClick={() => setActiveMenu('settings')}>
                            Profile
                        </DropdownItem>
                        <DropdownItem icon={<FiLogOut/>} onClick={props.signOut}>Logout</DropdownItem>
                    </div>

                </CSSTransition>

                <CSSTransition 
                in={activeMenu === 'settings'} 
                unmountOnExit
                timeout={500}
                classNames='menu-secondary'
                onEnter={calcHeight}
                >

                    <div className='menu'>
                        <DropdownItem icon={<FaChevronLeft/>} onClick={() => setActiveMenu('main')}>Go Back</DropdownItem>
                        <DropdownItem icon={<MdSettings/>}>Settings</DropdownItem>
                    </div>

                </CSSTransition>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
})

export default connect(null, mapDispatchToProps)(Dropdown)