import React from 'react';
import '../stylesheets/components/ShowCard.css';

const ShowCard = ({ post, isShown }) => {
    return (
        <section id='show-post' className={isShown ? 'shown' : 'hidden'}>
            <article>
                <time id='show-time'>{post.timeCreated.toDate().toDateString().substring(4)}</time>
                <h2 id='show-title'>{post.title}</h2>
                <img id='show-card-image' src={post.image} alt='article'/>
                <div id='show-content' dangerouslySetInnerHTML={post.content}></div>
            </article>
        </section>
    )
}

export default ShowCard