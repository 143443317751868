import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { signIn } from '../store/actions/authActions.js';
import { MdError } from 'react-icons/md';
import '../stylesheets/Login.css';

const Login = (props) => {
    const [login, setLogin] = useState({
        email: '',
        password: ''
    });
    const history = useHistory();
    
    const handleSignIn = (e) => {  
        e.preventDefault();
        props.signIn({email: login.email, password: login.password});
        if(!props.authError === null) return null;
        history.push('/dashboard');
    }

    return (
        <main id='login'>
            <div id='login-background'>
                <h1>Login</h1>
                <form id='login-form' 
                onSubmit={(e) => handleSignIn(e)}>
                    <section>
                        <input 
                        type='email' 
                        name='email' 
                        onChange={(e) => {setLogin({...login, email: e.target.value})}} 
                        placeholder='email@email.com' 
                        />
                        <label htmlFor='email' className='login-label'>
                            <span className="label-content">
                                Email
                            </span>
                        </label>  
                    </section>
                    <section>
                        <input 
                        type='password' 
                        name='password' 
                        onChange={(e) => {setLogin({...login, password: e.target.value})}}
                        placeholder='password' 
                        />
                        <label htmlFor='password' className='login-label'>
                            <span className="label-content">
                                Password
                            </span>
                        </label>
                    </section>
                        <input type='submit' />
                </form>
                <div className={props.authError ? 'login-error' : ''}>
                    {props.authError ? <p><MdError /> {props.authError}</p> : null}
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    authError: state.auth.authError
});

const mapDispatchToProps = dispatch => ({
    signIn: (creds) => dispatch(signIn(creds))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)