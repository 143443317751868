import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdPersonAdd } from 'react-icons/md';
import firebase from '../configs/FirebaseConfig.js';
import UserCard from '../components/UserCard.jsx';
import Loader from './partials/Loader';
import '../stylesheets/Users.css';

const Users = ({ userData }) => {
    const fetchAllUsers = firebase.functions().httpsCallable('fetchAllUsers');
    const [userList, setUserList] = useState(null);

    useEffect(() => {
        fetchAllUsers().then(result => setUserList(result.data));
        // eslint-disable-next-line
    }, []);

    return (
        <main className='users'>
            <h1 className='users-title'>Users</h1>
            {!userList ? <Loader /> : <ul className='user-card-list'>
                { userList.map(user => (
                    <UserCard key={user.displayName} user={user} />
                ))}
            </ul>}
            <Link to='/users/new'>
                <button className='new-user-button'>
                    <span id='new-user-button-text'>New User</span>
                    <MdPersonAdd />
                </button>
            </Link>
        </main>
    )
}

export default Users