import React, { useEffect, useState } from 'react';
import firebase from '../configs/FirebaseConfig.js';
import '../stylesheets/components/UserCard.css';

const UserCard = ({ user }) => {
    const firestore = firebase.firestore();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        firestore
            .collection('users')
            .doc(user.uid)
            .get()
            .then(doc => doc.exists ? setProfile(doc.data()) : null);
        // eslint-disable-next-line
    }, []);

    return (
        <li className='user-card'>
            <img className='user-card-image' src={user.photoURL ? user.photoURL : 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'} alt='User' />
            <h3 className='user-card-name'>{user.displayName}</h3>
            <p className='user-card-status'>{profile ? profile.userPermit : null}</p>
        </li>
    )
}

export default UserCard