import React, { useState } from 'react';

const Hamburger = (props) => {
    const [active, setActive] = useState(false);

    const hamburgerClick = () => {
        setActive(!active);
    }

    return (
        <div>
            <div onClick={hamburgerClick} className={active ? 'menu-btn open' : 'menu-btn'}>
                <div  className='hamburger-menu'></div>
            </div>
            {active && props.children}
         </div>
    )
}

export default Hamburger;