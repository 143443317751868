import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBrZ6avplNuVGqUAslTNvNXG0HAJdHbbx0",
    authDomain: "hawaii-trader-blog.firebaseapp.com",
    databaseURL: "https://hawaii-trader-blog.firebaseio.com",
    projectId: "hawaii-trader-blog",
    storageBucket: "hawaii-trader-blog.appspot.com",
    messagingSenderId: "797708575314",
    appId: "1:797708575314:web:d385be28a1fa74766cb58b",
    measurementId: "G-RJNR23ZHDM"
}

firebase.initializeApp(config);
firebase.analytics();

export default firebase